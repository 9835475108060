import { ClassNames } from "@emotion/react";
import React from "react";
import { twMerge } from "tailwind-merge";

interface IFile {
    filename?: string;
    className?: string;
}

function getExtension(file: string) {
    return file.split(".").pop()?.toLowerCase();
}

function isImage(file: string) {
    let extension = getExtension(file);
    return extension
        ? ["png", "jpeg", "jpg", "gif"].includes(extension)
        : false;
}

function isVideo(file: string) {
    let extension = getExtension(file);
    return extension ? ["mp4"].includes(extension) : false;
}

export default function File({ filename, className }: IFile) {
    if (!filename) return null;

    let ComponentFile: null | JSX.Element = null;

    if (isImage(filename)) {
        ComponentFile = (
            <img
                src={filename}
                className={twMerge("tw-w-full sm:tw-min-h-[355px]", className)}
            />
        );
    } else if (isVideo(filename)) {
        ComponentFile = (
            <video
                autoPlay
                className={twMerge("tw-w-full sm:tw-min-h-[355px]", className)}
            >
                <source src={filename} />
            </video>
        );
    }

    return <>{ComponentFile}</>;
}
